@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    .xmas-glow {
        -webkit-text-stroke-width: 0.1rem;
    }

    .xmas-glow-red {
        -webkit-text-stroke-color: red;
        text-shadow: 1px 1px 10px red;
    }

    .xmas-glow-green {
        -webkit-text-stroke-color: green;
        text-shadow: 1px 1px 10px green;
    }

    .xmas-glow-gold {
        -webkit-text-stroke-color: gold;
        text-shadow: 1px 1px 10px gold;
    }

    .xmas-glow-blue {
        -webkit-text-stroke-color: blue;
        text-shadow: 1px 1px 10px blue;
    }

    .xmas-glow-white {
        -webkit-text-stroke-color: white;
        text-shadow: 1px 1px 10px white;
    }

    .xmas-banner-shadow {
        background: rgb(20,20,20);
        background: linear-gradient(0deg, rgba(20,20,20,0.5) 0%, rgba(255,255,255,0) 22%, rgba(255,255,255,0) 78%, rgba(235,235,235,0.5) 100%);
    }
}

html, body, #root {
  height: 100% !important;
    overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
    -webkit-tap-highlight-color: transparent;
}

input:focus {
  outline: none !important;
  box-shadow: none !important;
}

.policy-container p {
    margin-bottom: 1rem !important;
}

.policy-container ul {
    list-style: disc;
    padding-left: 1rem;
    margin-bottom: 1rem !important;
}

.policy-container h3 {
    margin-top: 2rem !important;
    margin-bottom: 1rem !important;
    font-weight: bold;
}

.policy-container a {
    @apply text-primary-1
}

.policy-container a:hover {
    @apply text-primary-2
}

.banner-stripes-default-1 {
  background: repeating-linear-gradient(
          45deg,
          #f5f5f5,
          #f5f5f5 2.5rem,
          #202020 2.5rem,
          #202020 5rem
  );
}

.banner-stripes-default-2 {
  background: repeating-linear-gradient(
          135deg,
          #f5f5f5,
          #f5f5f5 2.5rem,
          #202020 2.5rem,
          #202020 5rem
  );
}


.banner-stripes-spookle-1 {
    background: repeating-linear-gradient(
            45deg,
            #d77219,
            #d77219 2.5rem,
            #202020 2.5rem,
            #202020 5rem
    );
}

.banner-stripes-spookle-2 {
    background: repeating-linear-gradient(
            135deg,
            #d77219,
            #d77219 2.5rem,
            #202020 2.5rem,
            #202020 5rem
    );
}

.banner-stripes-xmas-1 {
    background: repeating-linear-gradient(
            45deg,
            #f5f5f5,
            #f5f5f5 2.5rem,
            #ff2c28 2.5rem,
            #ff2c28 5rem
    );
}

.center-panel-ad-container {
    height: calc(100vh - calc(min(calc(40vh - 1rem), 56.25vw) + 4.75rem + 1px) - 12.625rem);
    width: calc((100vw - 640px) / 2);
    top: calc(min(calc(40vh - 1rem), 56.25vw) + 4.75rem + 1px);
    max-width: 17.5rem;
}
